export default {
  initialize: 'initialize',
  hashchange: 'hashchange',
  pending: 'pending',
  channelSelect: 'channelSelect',
  channelSelected: 'channelSelected',
  channelReceive: 'channelReceive',
  channelAdd: 'channelAdd',
  channelReset: 'channelReset',
  settingReceive: 'settingReceive',
  settingUpdate: 'settingUpdate',
  itemSelect: 'itemSelect',
  itemSelectRelative: 'itemSelectRelative',
  viewStart: 'viewStart',
  viewEnd: 'viewEnd',
  refScreen: 'refScreen',
};
